import React from 'react'
//import {AiOutlineArrowUp, AiOutlineArrowDown} from 'react-icons/ai'
import {FaTemperatureHigh} from 'react-icons/fa'
import {GiWindSlap} from 'react-icons/gi'
import {BsFillSunFill, BsSunset, BsDropletFill} from 'react-icons/bs'

const TemperatureAndDetails = () => {
  return (
    <div>
      <div className='flex items-center justify-center py-6 text-xl text-cyan-300'>
        <p>Cloudy</p>
      </div>

      <div className='flex flex-row items-center justify-between text-white py-3'>
        <img src="http://openweathermap.org/img/wn/01d@2x.png" alt="" className='w-20' />

        <p className='text-5xl'>34°</p>

        <div className='flex flex-col space-y-2'>

          <div className="flex font-light text-sm items-center justify-center">
            <FaTemperatureHigh size={18} className='mr-1'/>
            Real fell:
            <span className='font-medium ml-1'>32°</span>
          </div>

          <div className="flex font-light text-sm items-center justify-center">
            <BsDropletFill size={18} className='mr-1'/>
            Humidity:
            <span className='font-medium ml-1'>65%</span>
          </div>

          <div className="flex font-light text-sm items-center justify-center">
            <GiWindSlap size={18} className='mr-1'/>
            Wind Speed:
            <span className='font-medium ml-1'>11km/h</span>
          </div>
        </div>
      </div>

      <div className='flex flex-row items-center justify-center space-x-2 text-white text-sm py-3'>
        <BsFillSunFill/>
        <p className='font-light'>
          Rise:
          <span className='font-medium ml-1'>06:45 AM</span>
        </p>
        <p className='font-light'></p>

        <BsSunset/>
        <p className='font-light'>
          Set:
          <span className='font-medium ml-1'>07:45 PM</span>
        </p>
        <p className='font-light'></p>

        <BsFillSunFill/>
        <p className='font-light'>
          High:
          <span className='font-medium ml-1'>45°</span>
        </p>
        <p className='font-light'></p>

        <BsFillSunFill/>
        <p className='font-light'>
          Low:
          <span className='font-medium ml-1'>25°</span>
        </p>
      </div>
    </div>
  )
}

export default TemperatureAndDetails